<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>
    <b-navbar-nav
      class="nav align-items-center ml-auto"
    >
      <a
        v-if="subdomain"
        :href="`https://${subdomain}.mnassah.com`"
        class="mx-1"
        target="_blank"
      >
        <b-button
          size="sm"
          variant="light"
        >
          <FeatherIcon
            color="black"
            icon="EyeIcon"
          />
          {{ $t('visit-menu') }}
        </b-button>
      </a>
      <notification-dropdown />
      <profile-actions-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BButton, BLink, BNavbarNav, VBModal, VBToggle,
} from 'bootstrap-vue'
import ProfileActionsDropdown from '@/@bya3/components/navbar/ProfileActionsDropdown'

export default {
  components: {
    NotificationDropdown: () => import('../../@bya3/components/navbar/NotificationDropdown'),
    ProfileActionsDropdown,
    BLink,
    BNavbarNav,
    BButton,
    // Navbar Components
  },
  directives: {
    'b-toggle': VBToggle,
    'b-modal': VBModal,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => null,
    },
  },
  computed: {
    subdomain() {
      return this.$store.getters['auth/user']?.domain
    },
  },

}
</script>
